var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "bin-btn",
      style: { width: 50 + "px", height: 50 + "px" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.data.click.apply(null, arguments)
        },
      },
    },
    [
      _c("svg-icon", {
        staticClass: "ic-edit",
        attrs: { name: "ic-bin", width: 50 / 2.3, height: 50 / 2 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }